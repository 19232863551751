import React from 'react';
import { I18n } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import SafeComponent from '../../../shared/SafeComponent';
import { COMPANY_LOGOS } from './config';
import styles from './index.module.scss';
import 'lazysizes';

const TrustedBy = () => {
    const { isTouch } = getContext();

    return (
        <div className={styles.wrapper}>
            <span className={styles.text}>
                <I18n k="logged_out_homepage.trusted_by.title" />:
            </span>
            <ul>
                {COMPANY_LOGOS.map((company) => (
                    <li key={company}>
                        {isTouch ? (
                            <img
                                className="lazyload"
                                key={company}
                                data-src={company.image}
                                alt={company.alt}
                                width={company.width}
                                height={company.height}
                            />
                        ) : (
                            <img
                                key={company}
                                src={company.image}
                                alt={company.alt}
                                width={company.width}
                                height={company.height}
                            />
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export { TrustedBy };
export default SafeComponent(TrustedBy);

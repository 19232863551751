import React, { useRef } from 'react';
import { logger } from '@fiverr-private/obs';
import { LazyComponent } from '@fiverr-private/orca';
import { useIntersectionObserver } from '@fiverr-private/hooks';
import { Section } from '@fiverr-private/layout_components';
import { metric } from '../prefixedStats';

// eslint-disable-next-line react/display-name
export const lazyImport = (componentName, loader) => (props) => {
    const observableRef = useRef(null);

    const entry = useIntersectionObserver(observableRef, {
        rootMargin: '200px',
        threshold: 0,
        once: true,
    });

    return (
        <Section ref={observableRef} paddingTop="0" paddingBottom="0">
            {entry?.isIntersecting && (
                <LazyComponent
                    lazyImport={loader}
                    onError={(error) => reportLoadingError(componentName, error)}
                    fallback={null}
                    componentProps={props}
                />
            )}
        </Section>
    );
};

const reportLoadingError = (componentName, error) => {
    metric.count(`lazy_loading.fetch_error.${componentName}`);

    logger.warn(error, {
        description: 'Failed to lazy load asset',
        componentName,
    });
};

import { bigQuery, mixpanel } from '@fiverr-private/obs';
import { getContext } from '@fiverr-private/fiverr_context';
import { MIXPANEL_EVENT_TYPES } from '../types/mixpanelEventTypes';
import { DEFAULT_DATA } from './events/defaultData';
import * as mp from './events/mixPanelEvents';
import * as bq from './events/bigQueryEvents';
import { BIGQUERY_REVAMP_PAGE_NAME, PAGE_NAME } from './constants';

export const sendBigQueryEvent = ({ eventName, params = {} }) => {
    let eventData = {
        type: eventName,
        group: PAGE_NAME,
    };

    const { pageCtxId } = getContext();

    const baseLOHPParams = {
        page: {
            name: BIGQUERY_REVAMP_PAGE_NAME,
            ctx_id: pageCtxId,
        },
        group: 'user_actions',
        feature: {
            name: 'LOHP',
        },
    };

    const clickLOHPParams = {
        ...baseLOHPParams,
        action: {
            type: 'click',
        },
    };

    const hoverLOHPParams = {
        ...baseLOHPParams,
        action: {
            type: 'hover',
        },
    };

    switch (eventName) {
        case bq.BQ_CLICKED_GUEST_HOMEPAGE_CATEGORY_IMAGE:
            eventData = {
                ...eventData,
                category: {
                    id: params.id,
                    name: params.name,
                },
                position: params.position,
            };
            break;
        case bq.BQ_CLICKED_POPULAR_SERVICES_CAROUSEL:
            eventData = {
                ...eventData,
                ...clickLOHPParams,
                action: {
                    type: 'click',
                },
                position: params.position,
            };
            break;
        case bq.BQ_CLICKED_GETTING_STARTED:
            eventData = {
                ...eventData,
                ...clickLOHPParams,
                action: {
                    type: 'click',
                },
                position: params.position,
            };
            break;
        case bq.BQ_CLICKED_TESTIMONAILS_VIDEO:
            eventData = {
                ...eventData,
                ...clickLOHPParams,
                page: {
                    component: {
                        metadata: params.videoName,
                    },
                },
                position: params.position,
            };
            break;
        case bq.BQ_CLICKED_LM_BANNER:
            eventData = {
                ...eventData,
                ...clickLOHPParams,
            };
            break;
        case bq.BQ_HOVERED_USP:
            eventData = {
                ...eventData,
                ...hoverLOHPParams,
            };
            break;
        case bq.BQ_HOVERED_INSPIRE:
            eventData = {
                ...eventData,
                ...hoverLOHPParams,
            };
            break;
        case bq.BQ_HOVERED_TESTIMONIALS_VIDEO:
            eventData = {
                ...eventData,
                ...hoverLOHPParams,
            };
            break;
        case bq.BQ_CLICKED_CTA_BANNER:
            eventData = {
                ...eventData,
                ...clickLOHPParams,
            };
            break;
        case bq.BQ_CLICKED_HOMEPAGE_LO_SEARCH_TAG:
            eventData = {
                ...eventData,
                ...params,
            };
            break;
        case bq.BQ_CLICKED_USPSS_BUTTON:
            eventData = {
                ...eventData,
                ...params,
            };
            break;
        case bq.BQ_CLICKED_VONTELLE_CARD:
            eventData = {
                ...eventData,
                category: {
                    id: params.id,
                    name: params.name,
                },
                position: params.position,
            };
            break;
        case bq.BQ_CLICKED_HOMEPAGE_LO_CARD_MADE_ON_FIVERR:
            eventData = {
                ...eventData,
                position: params.pos,
                page: {
                    element: {
                        type: params.type,
                        text: params.item.subCategory.name,
                    },
                },
                category: {
                    id: params.item.category.id,
                    name: params.item.category.slug,
                },
                sub_category: {
                    id: params.item.subCategory.id,
                    name: params.item.subCategory.slug,
                },
                seller: {
                    id: params.item.seller.id,
                },
                gig: {
                    id: params.item.gig.id,
                },
            };
            break;
        default:
            break;
    }

    bigQuery.send(eventData);
};

export const sendMixPanelEvent = ({ eventName, eventType = MIXPANEL_EVENT_TYPES.STANDARD, params = {} }) => {
    let eventData = DEFAULT_DATA;

    switch (eventName) {
        case mp.CLICKED_POPULAR_SEARCH_TAG:
            eventData = {
                ...eventData,
                'Tag Name': params.name,
                'Tag Order': params.order,
            };
            break;
        case mp.CLICKED_TESTIMONIALS_VIDEO:
            eventData = {
                ...eventData,
                'Testimonial Order': params.order,
            };
            break;
        case mp.CLICKED_BANNER_SEARCH:
            eventData = {
                ...eventData,
                Query: params.query,
                Price: params.price,
                Delivery: params.delivery,
            };
            break;
        case mp.CLICKED_TESTIMONIALS_ARROW:
            eventData = {
                ...eventData,
                'Clicked element': params.direction,
            };
            break;
        case mp.MP_CLICKED_TOP_PRIORITY:
            eventData = {
                ...eventData,
                'Clicked element': params.element,
            };
            break;
        case mp.MP_FAQ_CLICKED:
            eventData = {
                ...eventData,
                'Clicked element': params.element,
            };
            break;
        case mp.MP_VONTELLE_CARD_CLICKED:
        case mp.MP_CAROUSEL_CLICKED:
            eventData = {
                ...eventData,
                'Clicked element': params.element,
                'Clicked element order': params.order,
            };
            break;
        case mp.MP_CATEGORIES_ICON_CLICKED:
            eventData = {
                ...eventData,
                'Clicked element': params.categoryName,
                'Clicked element order': params.position,
            };
            break;
        case mp.MP_FIB_CLICK:
        case mp.MP_BRAND_VIDEO_CLICK:
            eventData = {
                ...eventData,
            };
            break;
        default:
            break;
    }

    if (eventType === MIXPANEL_EVENT_TYPES.PAGE_VIEW) {
        mixpanel.trackPageView({
            'Page Name': eventName,
            ...eventData,
        });
    } else {
        mixpanel.track(eventName, eventData);
    }
};

export const getImpressionEnrichment = ({ eventName }) => {
    const { pageCtxId } = getContext();

    const impressionLOHPParams = {
        type: eventName,
        page: {
            name: BIGQUERY_REVAMP_PAGE_NAME,
            ctx_id: pageCtxId,
        },
        group: 'user_actions',
        feature: {
            name: 'LOHP',
        },
        action: {
            type: 'impression',
        },
    };

    return { enrichment: impressionLOHPParams };
};

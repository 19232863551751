import React from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { useImpressionItem } from '@fiverr-private/impressionable';
import { MAX_WIDTH_CONTAINER } from '../../utils/constants';
import SafeComponent from '../../shared/SafeComponent';
import { getImpressionEnrichment } from '../../utils/biEvents';
import { BQ_IMP_TOP_BANNER_IS_SHOWN } from '../../utils/biEvents/events/bigQueryEvents';
import Header from './Header';
import NewHeader from './NewHeader';
import TrustedBy from './TrustedBy';

import styles from './index.module.scss';

const Hero = ({ isInRevampTestOut }) => {
    const [ref] = useImpressionItem(getImpressionEnrichment({ eventName: BQ_IMP_TOP_BANNER_IS_SHOWN }));

    return (
        <div className={classNames(MAX_WIDTH_CONTAINER, styles.container)} ref={ref}>
            <div className={styles.wrapper}>
                {isInRevampTestOut ? <NewHeader /> : <Header />}
                <TrustedBy />
            </div>
        </div>
    );
};

Hero.propTypes = {
    isInRevampTestOut: bool,
};

export { Hero };
export default SafeComponent(Hero);

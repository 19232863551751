import React from 'react';
import { bool } from 'prop-types';
import { getContext } from '@fiverr-private/fiverr_context';
import { useImpressionManager } from '@fiverr-private/impressionable';
import { lazyImport } from '../../../apps/logged_out_homepage/utils/newLazyImport';
import shouldShowLogoMakerBanner from '../../../apps/logged_out_homepage/utils/shouldShowLogoMakerBanner';
import Hero from '../../../apps/logged_out_homepage/components/Hero';
import Categories from '../../../apps/logged_out_homepage/components/Categories';
import NewCategories from '../../../apps/logged_out_homepage/components/NewCategories';
import Seo from '../../../apps/logged_out_homepage/shared/Seo';
import EntryWrapper from '../../../apps/logged_out_homepage/components/EntryWrapper';
import SubcategoryCarousel from '../../../apps/logged_out_homepage/components/SubcategoryCarousel';
import { getSubCategories } from '../../../apps/logged_out_homepage/components/SubcategoryCarousel/config';
import { shouldLoadPersonalizedSection } from '../../../apps/logged_out_homepage/components/PersonalizedSectionWrapper/shouldLoad';
import { LAZY_COMPONENTS } from './constants';

const MadeOnFiverr = lazyImport(
  LAZY_COMPONENTS.MADE_ON_FIVERR,
  () => import(/* webpackChunkName: 'MadeOnFiverr' */ '../../../apps/logged_out_homepage/components/MadeOnFiverr')
);

const Testimonials = lazyImport(
  LAZY_COMPONENTS.TESTIMONIALS,
  () => import(/* webpackChunkName: 'Testimonials' */ '../../../apps/logged_out_homepage/components/Testimonials')
);

const Video = lazyImport(
  LAZY_COMPONENTS.VIDEO,
  () => import(/* webpackChunkName: 'Video' */ '../../../apps/logged_out_homepage/components/Video')
);

const FiverrBusiness = lazyImport(
  LAZY_COMPONENTS.FIVERR_BUSINESS,
  () => import(/* webpackChunkName: 'FiverrBusiness' */ '../../../apps/logged_out_homepage/components/FiverrBusiness')
);

const NewFiverrBusiness = lazyImport(
  LAZY_COMPONENTS.NEW_FIVERR_BUSINESS,
  () =>
    import(/* webpackChunkName: 'NewFiverrBusiness' */ '../../../apps/logged_out_homepage/components/NewFiverrBusiness')
);

const FiverrGuides = lazyImport(
  LAZY_COMPONENTS.FIVERR_GUIDES,
  () => import(/* webpackChunkName: 'FiverrGuides' */ '../../../apps/logged_out_homepage/components/FiverrGuides')
);

const Signup = lazyImport(
  LAZY_COMPONENTS.SIGNUP,
  () => import(/* webpackChunkName: 'Signup' */ '../../../apps/logged_out_homepage/components/Signup')
);

const LogoMakerBanner = lazyImport(
  LAZY_COMPONENTS.LOGO_MAKER_BANNER,
  () => import(/* webpackChunkName: 'LogoMakerBanner' */ '../../../apps/logged_out_homepage/components/LogoMakerBanner')
);

const PersonalizedSectionWrapper = lazyImport(
  LAZY_COMPONENTS.PERSONALIZED_SECTION_WRAPPER,
  () =>
    import(
      /* webpackChunkName: 'PersonalizedSectionWrapper' */ '../../../apps/logged_out_homepage/components/PersonalizedSectionWrapper'
    )
);

const Fingertips = lazyImport(
  LAZY_COMPONENTS.FINGERTIPS,
  () => import(/* webpackChunkName: 'Fingertips' */ '../../../apps/logged_out_homepage/components/Fingertips')
);

const Freelancers = lazyImport(
  LAZY_COMPONENTS.FREELANCERS,
  () => import(/* webpackChunkName: 'Freelancers' */ '../../../apps/logged_out_homepage/components/Freelancers')
);

const ProServices = lazyImport(
  LAZY_COMPONENTS.PRO_SERVICES,
  () => import(/* webpackChunkName: 'ProServices' */ '../../../apps/logged_out_homepage/components/ProServices')
);

const NewVideo = lazyImport(
  LAZY_COMPONENTS.NEW_VIDEO,
  () => import(/* webpackChunkName: 'NewVideo' */ '../../../apps/logged_out_homepage/components/NewVideo')
);

const LoggedOutHomepageClient = ({ userWasLoggedIn, displayBrandCampaign, isInRevampTestOut }) => {
  useImpressionManager();
  const showLogoMakerBanner = shouldShowLogoMakerBanner();
  const { locale } = getContext();

  const shouldLoad = shouldLoadPersonalizedSection({ userWasLoggedIn });

  return (
    <EntryWrapper displayBrandCampaign={displayBrandCampaign}>
      <Seo />
      <Hero isInRevampTestOut={isInRevampTestOut} />
      {isInRevampTestOut ? <NewCategories /> : <Categories />}
      {shouldLoad && <PersonalizedSectionWrapper userWasLoggedIn={userWasLoggedIn} />}
      <SubcategoryCarousel subCategories={getSubCategories({ locale })} />
      {!!isInRevampTestOut && <NewFiverrBusiness />}
      {!isInRevampTestOut && <Fingertips />}
      {isInRevampTestOut ? <NewVideo /> : <Video />}
      {!!isInRevampTestOut && <ProServices />}
      {!!isInRevampTestOut && <Freelancers />}
      {!isInRevampTestOut && <FiverrBusiness />}
      {!isInRevampTestOut && <Testimonials />}
      {showLogoMakerBanner && <LogoMakerBanner />}
      <MadeOnFiverr />
      <FiverrGuides />
      <Signup isInRevampTestOut={isInRevampTestOut} />
    </EntryWrapper>
  );
};

LoggedOutHomepageClient.propTypes = {
  userWasLoggedIn: bool,
  displayBrandCampaign: bool,
  isInRevampTestOut: bool,
};

export default LoggedOutHomepageClient;

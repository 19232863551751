import React from 'react';
import { string } from 'prop-types';
import SearchBar from '@fiverr-private/search_bar/src/components/SearchBar';
import { I18n, translate } from '@fiverr-private/i18n-react';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Typography } from '@fiverr-private/typography';
import SafeComponent from '../../../shared/SafeComponent';

import { titleCustomClass, italicCustomClass } from './styles.ve.css';

const NewHeader = ({ searchPlaceholderKey }) => {
    const placeholder = searchPlaceholderKey || 'logged_out_homepage.hero.search';

    return (
        <Stack gap="10" alignItems="center" direction="column">
            <Typography as="h1" color="white" fontWeight="normal" textAlign="center" className={titleCustomClass}>
                <I18n
                    k="logged_out_homepage.hero.h1"
                    templates={{
                        i: (text) => (
                            <Typography as="i" fontFamily="domaine" fontWeight="normal" className={italicCustomClass}>
                                {text}
                            </Typography>
                        ),
                    }}
                />
            </Typography>

            <Container width={{ default: '100%', md: '436px', lg: '560px', xl: '616px' }}>
                <SearchBar placeholder={translate(placeholder)} source="main_banner" submitButtonType="button_inside" />
            </Container>
        </Stack>
    );
};

NewHeader.propTypes = {
    searchPlaceholderKey: string,
};

export { NewHeader };
export default SafeComponent(NewHeader);

import React from 'react';
import { translate } from '@fiverr-private/i18n-react';
import LazyImage from '../LazyImage';

interface TouchImageProps {
  subCategoryNameKey: string;
  image: {
    touchSrc: string;
    touchSrcSet: string;
    src: string;
  };
}

export const TouchImage = (props: TouchImageProps) => {
  const { subCategoryNameKey, image } = props;

  return <LazyImage alt={translate(subCategoryNameKey)} src={image.touchSrc} srcSet={image.touchSrcSet} />;
};

import meta from '../../../assets/companies/meta.svg';
import google from '../../../assets/companies/google.svg';
import netflix from '../../../assets/companies/netflix.svg';
import pg from '../../../assets/companies/pg.svg';
import paypal from '../../../assets/companies/paypal.svg';
import payoneer from '../../../assets/companies/payoneer.svg';

export const COMPANY_LOGOS = [
    {
        alt: 'meta',
        image: meta,
        width: 70,
        height: 14,
    },
    {
        alt: 'Google',
        image: google,
        width: 53.41,
        height: 17.87,
    },
    {
        alt: 'NETFLIX',
        image: netflix,
        width: 53.64,
        height: 14.37,
    },
    {
        alt: 'P&G',
        image: pg,
        width: 33.13,
        height: 13.8,
    },
    {
        alt: 'PayPal',
        image: paypal,
        width: 53.01,
        height: 12.69,
        displayFromSm: true,
    },
    {
        alt: 'Payoneer',
        image: payoneer,
        width: 82.42,
        height: 16,
        displayFromSm: true,
    },
];
